#sharerul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sharerIcon {
  margin-bottom: 1px;
  margin-left: 1px;
  margin-top: 1px;
  margin-right: 20px;
  font-size: 180%;
}