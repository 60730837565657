.roboto {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.calligraffitti {
    font-family: 'Calligraffitti', cursive;
}

.montserrat {
    font-family: 'Raleway', sans-serif;
}

.big {
    font-size: 400%;
}

.bigger {
    font-size: 600%;
}

.biggest {
    font-size: 800%;
}

.huge {
    font-size: 1000%;
}

.gigantic {
    font-size: 3000%;
}

.collosal {
    font-size: 5000%;
}

/* styles/fonts.css */
@font-face {
    font-family: 'Cascadia';
    src: url('../fonts/Cascadia.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Apply the font to specific elements or globally */
code {
    font-family: 'Cascadia', sans-serif;
}