.introduction {
    font-style: italic;
}

.title {
    font-size: 400%;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

@media (max-width: 768px) {
    .title {
        font-size: 250%;
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
    }
}

.headings {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

figcaption {
    text-align: center;
}

.bhead {
    padding: 10px 10px 10px 0px;
    margin: 52px 0 10px 0px;
    text-align: left;
}