/* navbar */
.navbar-default {
    background-image: linear-gradient(-45deg, #000000, #111111, #222222, #333333);
}
/* Title */
.navbar-default .navbar-brand {
    color: rgb(85,172,238);
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: rgb(85,172,238);
}
/* Link */
.navbar-default .navbar-nav > li > a {
    color: white;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: white;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: white;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: white;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
  
.custom-toggler.navbar-toggler {
    border-color: white;
} 

.nav-item {
    margin-left: 10px;
    margin-right: 10px;
}

.navbar-nav {
    margin-right: 140px;
}

ul li a {
    text-align: left;
}