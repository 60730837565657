.featuredText {
    padding: 0px 0 8px 0;
}

.paperpill {
    border-radius: 25px;
    margin: 2px;
    border-color: transparent;
    padding: 6px 12px 6px 12px;
    background-image: linear-gradient(to right, #045304, #107C10);
    border: 1px;
    white-space: nowrap;
    color: white;
}

.paperpill:hover {
    box-shadow: 0 0 10px #107C10;
    color: white;
}

.blogpill {
    border-radius: 25px;
    margin: 2px;
    border-color: transparent;
    padding: 6px 12px 6px 12px;
    background-image: linear-gradient(to right, #e0003d, #a100b7);
    border: 1px;
    white-space: nowrap;
    color: white;
}

.blogpill:hover {
    box-shadow: 0 0 10px #f200b1;
    color: white;
}

.projectpill {
    border-radius: 25px;
    margin: 2px;
    border-color: transparent;
    padding: 6px 12px 6px 12px;
    background-image: linear-gradient(to right, #2a36da, #0075ff);
    border: 1px;
    white-space: nowrap;
    color: white;
}

.projectpill:hover {
    box-shadow: 0 0 10px #0075ff;
    color: white;
}

.body {
    background-image: linear-gradient(to right, #111111, #222222);
    color: white;
}

hr {
    background-color: #333333;
}

.blogLink,
.blogLink:hover {
    text-decoration: none;
    color: white;
}

.gt1 {
    background: -webkit-linear-gradient(#e0003d, #a100b7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gt2 {
    background: -webkit-linear-gradient(#2a36da, #0075ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gt3 {
    background: -webkit-linear-gradient(#AA771C, #ffc107);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gt4 {
    background: -webkit-linear-gradient(#045304, #107C10);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.paginationIcon {
    margin: 5px;
}

.pagination {
    display: flex;
    justify-content: left;
    align-items: left;
}

.pagination button {
    border-radius: 25px;
}

.paperLink {
    padding: 6px 12px 6px 12px;
}