body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #f9f7f0; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.layout {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    display: flex;
    flex: 1;
    padding: 20px;
    margin-top: 32px;
}

img {
    border-radius: 3px;
}