footer {
    padding: 10px;
    color: white;
    background-image: linear-gradient(45deg, #000000, #111111, #222222, #333333);
}

.socialIcon {
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 200%;
}

#footerContent {
    margin: 0;
    padding: 0;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    text-align: center;
}

#footerul {
    margin: 0;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;
}